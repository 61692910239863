import React from "react";
import { Modal } from "../../../components/modal";
import { TEXT } from "../../../const";
import { t } from "../../../utils";
import { dismissModal } from "../../../store/form/actions";
import { connect } from "react-redux";
import { IconCheck } from "../../../components/icons/check";

import "./style.scss";

interface KycSuccessModalProps {
  dismissModal: () => void;
}

export const KycSuccessModalInner = (props: KycSuccessModalProps) => {
  setTimeout(props.dismissModal, 3000);
  return (
    <Modal
      icon={<IconCheck />}
      headingText={"Submission Successful"}
      onClose={props.dismissModal}
    >
      {t(TEXT.QEX_YOUR_DOCUMENTS_APPROVED)}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: (a: any) => void) => ({
  dismissModal: () => dispatch(dismissModal()),
});

export const KycSuccessModal = connect(
  null,
  mapDispatchToProps
)(KycSuccessModalInner);
