import { KYC_DOCUMENT_STATUS, KYC_ELEMENT, SUMSUB_FLOW_NAMES } from "const";
import jwt from "../utils/jwt";
import { UserSessionToken } from "store/configuration/types";
import { KycStateCategory, KycState, AppState } from "../store";

export const storeSessionId = (
  partnerKey: string,
  sessionId: string,
  sessionSecret?: string
) => {
  try {
    if (window.localStorage !== undefined && window.localStorage !== null) {
      window.localStorage.setItem(
        `sessionId:${partnerKey}`,
        `${sessionId}:${sessionSecret}`
      );
    }
  } catch {}
};

export const retrieveSessionId = (
  partnerKey: string
): UserSessionToken | null => {
  try {
    if (window.localStorage !== undefined && window.localStorage !== null) {
      // backwards compatability. parent frames _should_ be supplying fully qualified keys
      const possibleKeys = [
        `sessionId:${partnerKey}`,
        `sessionId:pk_${partnerKey}`,
        `sessionId:pk_sandbox_${partnerKey}`,
      ];
      for (const key of possibleKeys) {
        const s = window.localStorage.getItem(key);
        if (s !== null) {
          const parts = s.split(":");
          if (
            parts[0] !== null &&
            parts[0] !== undefined &&
            parts[0].trim().length === 36
          ) {
            return {
              session_id: parts[0],
              session_secret: parts[1],
            };
          }
        }
      }
    }
  } catch {}
  return null;
};

export const createJwt = (
  token: UserSessionToken,
  path: string,
  nonce?: number
) => {
  const { session_id, session_secret } = token;
  if (
    session_id === null ||
    session_id === undefined ||
    session_secret === null ||
    session_secret === undefined
  ) {
    return undefined;
  }
  if (nonce === undefined) {
    nonce = Date.now();
  }

  return "Bearer " + jwt.encode({ path, nonce, session_id }, session_secret);
};

export const needToComplete = (
  itemState: KycStateCategory,
  attemptSkip: boolean
) => {
  if (itemState.status === KYC_DOCUMENT_STATUS.APPROVED) {
    return false;
  }
  if (attemptSkip && itemState.deferrable) {
    return false;
  }
  return true;
};

export const deriveKycForm = (
  kycState: KycState,
  poaSkipped: boolean
): SUMSUB_FLOW_NAMES | null => {
  if (needToComplete(kycState.documents[KYC_ELEMENT.POI], false)) {
    return SUMSUB_FLOW_NAMES.Identification;
  }
  if (needToComplete(kycState.documents[KYC_ELEMENT.LIVENESS], false)) {
    return SUMSUB_FLOW_NAMES.FaceMatch;
  }
  if (needToComplete(kycState.documents[KYC_ELEMENT.POA], poaSkipped)) {
    return SUMSUB_FLOW_NAMES.ResidentialAddress;
  }
  return null;
};

export const getExistingSessionConf = (
  apiKey?: string,
  urlParams?: URLSearchParams
): UserSessionToken | null => {
  if (apiKey !== undefined) {
    const sessionStore = retrieveSessionId(apiKey);
    if (sessionStore !== null) {
      return sessionStore;
    }
  }
  if (urlParams !== undefined) {
    const urlSession = urlParams.get("s");
    if (urlSession !== null) {
      return {
        session_id: urlSession,
      };
    }
  }
  return null;
};

export function storeRedirect(sessionId: string, redirect?: string | null) {
  if (redirect !== undefined && redirect !== null && redirect !== "") {
    window.localStorage.setItem(`${sessionId}:redirect`, redirect);
  }
}

export function fetchRedirect(sessionId: string) {
  return window.localStorage.getItem(`${sessionId}:redirect`);
}
