import { FORM_STEP, TEXT, PARTNER_ENVIRONMENT } from "../../const";
import React from "react";
import { connect } from "react-redux";
import { AppState } from "store";
import { resetForm } from "store/form/actions";
import { DisplayError } from "types";
import { t } from "utils/t";
import "./style.scss";
import { CsButton } from "components/cs-button";
import { IconFailed } from "Icons/index";
import { ErrorText } from "components/error-text";

interface FailureViewProps {
  errors: Array<DisplayError>;
  resetForm: () => void;
  environment?: PARTNER_ENVIRONMENT | null;
  kyc?: any;
  form?: any;
}

export const FailureViewInner = (props: FailureViewProps) => {
  const { errors, environment } = props;

  return (
    <div className="failure-view">
      <div>
        <div className="failure-view-header">
          <IconFailed />
          <h4 className="title">Service busy</h4>
        </div>

        <div className="txDetailsBlock full-width-content-padded">
          {environment === PARTNER_ENVIRONMENT.SANDBOX && (
            <>
              <ErrorText message={errors[0].message} />
              <p>
                <pre>{JSON.stringify(props.kyc)}</pre>
              </p>
              <p>
                <pre>{JSON.stringify(props.form)}</pre>
              </p>
            </>
          )}
        </div>
        <CsButton
          onClick={() => {
            props.resetForm();
          }}
        >
          {t(TEXT.QEX_RETRY)}
        </CsButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { session, form } = state;
  return {
    errors: state.form.errorState,
    environment: session.current !== null ? session.current.environment : null,
    kyc: state.kyc.current,
    form: state.form,
  };
};

const mapDispatchToProps = (dispatch: (a: any) => void) => ({
  resetForm: () => dispatch(resetForm()),
});

export const FailureView = connect(
  mapStateToProps,
  mapDispatchToProps
)(FailureViewInner);
