export enum TEXT {
  SIGN_IN_TO_BUY_WITH = "qex-signin-to-buy-with-icon",
  QEX_BUY_WITH_VISA = "qex-buy-with-icon",
  MIN = "qex-min",
  MAX = "qex-max",
  QEX_INVALID_NUMBER = "qex-invalid-number",
  QEX_INVALID_PRODUCT = "qex-invalid-product",
  QEX_YOU_PAY = "qex-you-pay",
  QEX_YOU_GET = "qex-you-receive",
  QEX_YOU_GET_REQUOTE = "qex-you-receive-requote",
  QEX_YOU_GOT = "qex-you-received",
  QEX_YOU_WILL_GET = "qex-you-will-receive",
  QEX_FIAT = "qex-fiat",
  QEX_CRYPTO = "qex-crypto",
  QEX_TOTAL_CARD_FEES = "qex-total-card-fees",
  QEX_CONVERSION_RATE = "qex-conversion-rate",
  QEX_NAME = "qex-name",
  QEX_NAME_PLACEHOLDER = "qex-name-placeholder",
  QEX_ADDRESS = "qex-address",
  QEX_ADDRESS_PLACEHOLDER = "qex-address-placeholder",
  QEX_PHONE = "qex-phone",
  QEX_PHONE_PLACEHOLDER = "qex-phone-placeholder",
  QEX_PHONE_INVALID = "qex-phone-invalid",
  QEX_EMAIL = "qex-email",
  QEX_EMAIL_PLACEHOLDER = "qex-email-placeholder",
  QEX_EMAIL_INVALID = "qex-email-invalid",
  QEX_CARD = "qex-card",
  QEX_PAY = "qex-pay",
  QEX_YOU_PAID = "qex-you-paid",
  QEX_ORDER = "qex-order",
  QEX_WALLET = "qex-wallet",
  QEX_HASH = "qex-hash",
  QEX_CHECK_CARD_DETAILS = "qex-check-card-details",
  QEX_SOMETHING_WENT_WRONG = "qex-something-went-wrong",
  QEX_WASNT_THAT_EASY = "qex-success-heading",
  QEX_TX_COMPLETE_LIQUID_WALLET = "qex-transaction-complete-liquid-wallet",
  QEX_FAIL_HEADING = "qex-failure-heading",
  QEX_FAIL_DESC = "qex-failure-description",
  QEX_COMP_HEADING = "qex-complete-heading",
  QEX_COMP_DESC = "qex-complete-description",
  QEX_INCOMP_HEADING = "qex-incomplete-heading",
  QEX_INCOMP_DESC = "qex-incomplete-description",
  QEX_COMING_SOON_HEADING = "qex-coming-soon-heading",
  QEX_COMING_SOON_INFO = "qex-coming-soon-info",
  QEX_COMING_SOON_DESC = "qex-coming-soon-desc",
  QEX_COMING_SOON_BUTTON_TEXT = "qex-coming-soon-button-text",
  QEX_COMING_SOON_COUNTRY_TEXT = "qex-coming-soon-country-text",
  QEX_COMING_SOON_LEARN_MORE = "qex-coming-soon-learn-more",
  QEX_ADDRESS_NOT_FOUND = "qex-address-not-found",
  QEX_ADDRESS_ENTER_MANUALLY = "qex-enter-address-manually",
  QEX_QUOTES_HEADING = "qex-quotes-heading",
  QEX_QUOTES_TOS_TEXT = "qex-quotes-tos-text",
  QEX_QUOTES_TOS_LINK = "qex-quotes-tos-link",
  QEX_PAYOUT_HEADING = "qex-payout-heading",
  QEX_PAYOUT_DESC = "qex-payout-desc",
  QEX_PAYOUT_DESC_WARNING_1 = "qex-payout-desc-warning-1",
  QEX_PAYOUT_DESC_WARNING_2 = "qex-payout-desc-warning-2",
  QEX_PAYOUT_NO_WALLET_TEXT = "qex-payout-no-wallet-text",
  QEX_PAYOUT_PLACEHOLDER_RECIPIENT = "qex-payout-placeholder-recipient",
  QEX_PAYOUT_PLACEHOLDER_WALLET_ADDRESS = "qex-payout-placeholder-wallet-address",
  QEX_PAYOUT_PLACEHOLDER_MEMO_ID = "qex-payout-placeholder-memo-id",
  QEX_DEPOSIT_HEADING_SEND = "qex-deposit-heading-send",
  QEX_DEPOSIT_HEADING_TO_ADDRESS = "qex-deposit-heading-to-address",
  QEX_DEPOSIT_DESC = "qex-deposit-desc",
  QEX_NEXT = "qex-next",
  QEX_BUY_NOW = "qex-buy-now",
  QEX_SWAP_NOW = "qex-swap-now",
  QEX_CARD_NUMBER = "qex-card-number",
  QEX_CARD_EXPIRY = "qex-mm-yy",
  QEX_CARD_CVV = "qex-cvv",
  QEX_DOC_HEADING = "qex-kyc-prepare-description",
  QEX_DOC_TITLE = "qex-identify-document",
  QEX_SELFIE_TITLE = "qex-selfie-only",
  QEX_SELFIE_DESC = "qex-selfie-detail",
  QEX_DOC_DESC = "qex-detail-note-document",
  QEX_POA_TITLE = "qex-poa-title",
  QEX_POA_DESC = "qex-poa-desc",
  QEX_QR_HANDOFF_HEADING = "qex-kyc-qr-handoff-heading",
  QEX_KYC_PREPARE_HEADING = "qex-kyc-prepare-title",
  QEX_ALT_LABEL = "qex-alt-label",
  QEX_VISA_CARD_ONLY = "qex-visa-card-only",
  QEX_STREET_ADDRESS = "qex-street-address",
  QEX_CITY = "qex-city",
  QEX_ZIP = "qex-zip",
  QEX_SELECT_COUNTRY = "qex-select-country",
  QEX_SELECT_REGION = "qex-select-region",
  QEX_WALLET_ADDRESS_REQUIRED = "qex-wallet-address-required",
  QEX_INVALID_NAME = "qex-invalid-name",
  QEX_SIGIN_WITH_LIQUID = "qex-signin-with-liquid",
  QEX_CREATE_ACCOUNT_WITH_LIQUID = "qex-create-an-account-liquid",
  QEX_SIGIN_WITH_LIQUID_HEADING = "qex-signin-with-liquid-heading",
  QEX_STREET_ADDRESS_REQUIRED = "qex-street-address-required",
  QEX_BILLING_ADDRESS_REQUIRED = "qex-billing-address-required",
  QEX_ENTER_ZIP_CODE = "qex-enter-zip-code",
  QEX_ENTER_POSTAL_CODE = "qex-enter-postal-code",
  QEX_SIGN_IN_TO_SWAP = "qex-signin-to-swap",
  QEX_SIGNING_INTO_LIQUID = "qex-signing-into-liquid",
  QEX_SIGNING_INTO_LIQUID_DESC = "qex-signing-into-liquid-desc",
  QEX_QUOTE_EXPIRED = "qex-quote-expired",
  QEX_QUOTE_EXPIRED_DESC = "qex-quote-expired-desc",
  QEX_REQUEST_NEW_DEAL = "qex-request-new-deal",
  QEX_BACK = "qex-back",
  QEX_COMPLETE_ON_MOBILE = "qex-complete-on-mobile",
  QEX_SCAN_QR_CODE = "qex-scan-qr-code",
  QEX_COMPLETE_ACCOUNT_VERIFICATION = "qex-complete-account-verification",
  QEX_POA_PROOF_DESC = "qex-poa-proof-desc",
  QEX_POA_TITLE_INELIGIBLE = "qex-poa-title-ineligible",
  QEX_POA_SKIP_FOR_NOW = "qex-poa-skip-for-now",
  QEX_DEAL_NOT_AVAILABLE = "qex-deal-not-available",
  QEX_NEW_QUOTE_AVAILABLE = "qex-new-quote-available",
  QEX_EXPIRED = "qex-expired",
  QEX_NEW_QUOTE = "qex-new-quote",
  QEX_CHANGE = "qex-change",
  QEX_RATE = "qex-rate",
  QEX_BUY = "qex-buy",
  QEX_ACCEPT_NEW_QUOTE = "qex-accept-new-quote",
  QEX_RETURN_TO_QUOTE_VIEW = "qex-return-to-quote-view",
  CARD_ISSUER_DECLINED_TRANSACTION = "qex-card-issuer-declined-tx-heading",
  QEX_CARD_NOT_CHARGED = "qex-card-not-charged",
  CARD_ISSUER_DECLINED_TRANSACTION_DESC = "qex-card-issuer-declined-tx-desc",
  CARD_ISSUER_DECLINED_COUNTRY_HEADING = "qex-card-issuer-declined-country-heading",
  QEX_DEBIT_CARD_ONLY = "qex-debit-card-only",
  QEX_TRY_AGAIN = "qex-try-again",
  MASTERCARD_DECLINE_REASON = "qex-mastercard-declined-reason",
  QEX_BUY_WITH = "qex-buy-with-text",
  BLOCKCHAIN_UNEXPECTED_BROADCAST_RESPONSE = "qex-blockchain-unexpected-response",
  BLOCKCHAIN_DEPOSIT_TIMEOUT = "qex-blockchain-deposit-timeout",
  CARD_VELOCITY_THRESHOLD = "qex-card-velocity-threshold",
  CARD_ISSUER_NOT_ALLOWED = "qex-card-issuer-not-allowed",
  CARD_ISSUER_COUNTRY_NOT_ALLOWED = "qex-card-issuer-country-not-allowed",
  CARD_INSUFFICIENT_FUNDS = "qex-card-insufficient-funds",
  CARD_3DS_FAILED = "qex-card-3ds-failed",
  CARD_CVV_FAILED = "qex-card-cvv-failed",
  CARD_EXPIRED = "qex-card-expired",
  CARD_BILLING_ADDRESS_INVALID = "qex-card-invalid-billing-address",
  PAYOUT_COMPLETED = "qex-payout-complete",
  USER_INPUT_TIMEOUT = "qex-user-input",
  TRANSACTION_HISTORY = "qex-transaction-history",
  SIGNOUT = "qex-signout",
  FILLED = "qex-filled",
  VOID = "qex-void",
  PENDING = "qex-pending",
  QEX_QUANTITY_EXCEEDED = "qex-quantity-exceeded",
  NO_RECORDS_FOUND = "qex-no-records-found",
  ABANDONED = "qex-card-not-charged",
  QEX_OUTCOME_HEADING = "qex-outcome-heading",
  QEX_BUY_MORE = "qex-buy-more",
  QEX_START_OVER = "qex-start-over",
  QEX_BUY_WITH_CARD = "qex-buy-with-card",
  QEX_WE_ACCEPT = "qex-we-accept",
  QEX_WE_ACCEPT_NOTE = "qex-we-accept-note",
  QEX_STATUS = "qex-status",
  QEX_DATE = "qex-date",
  QEX_ADDRESS_SEARCH_MODE = "qex-address-search-mode",
  QEX_ADDRESS_MANUALLY_MODE = "qex-address-manually-mode",
  QEX_CREATE_ACCOUNT_TAB = "qex-create-account-tab",
  QEX_LIQUID_CONNECT_TAB = "qex-liquid-connect-tab",
  QEX_LIQUID_CONNECT_BUTTON = "qex-liquid-connect-button",
  QEX_TITLE = "qex-title",
  QEX_LOADING = "qex-loading",
  QEX_NO_TRANSACTION = "qex-no-transaction",
  QEX_TECHNICAL_INFORMATION = "qex-technical-information",
  QEX_WAITING = "qex-waiting",
  QEX_YOUR_DOCUMENTS_APPROVED = "qex-your-documents-approved",
  QEX_BY_LIQUID = "qex-by-liquid",
  QEX_UNAVAILABLE_DESC = "qex-unavailable-desc",
  QEX_GOTO_QEX_LIQUID_LINK = "qex-goto-qex-liquid-link",
  QEX_UNDER_MAINTERNANCE = "qex-under-maintenance",
  QEX_RETRY = "qex-retry",
  QEX_REGISTER_TITLE = "qex-register-title",
  QEX_REGISTER_DESC1 = "qex-register-desc-1",
  QEX_REGISTER_DESC2 = "qex-register-desc-2",
  QEX_REGISTER_DESC3 = "qex-register-desc-3",
  QEX_REGISTER_DESC4 = "qex-register-desc-4",
  QEX_DIAGNOSTIC_TITLE = "qex-diagnostic-title",
  QEX_DIAGNOSTIC_MAYBE_TAKENFUNDS = "qex-diagnostic-maybe-takenfunds",
  QEX_DIAGNOSTIC_DESC = "qex-diagnostic-desc",
  QEX_WAITING_TRANSACTION_BLOCKCHAIN = "qex-waiting-transaction-blockchain",
  QEX_KYC_TITLE = "qex-kyc-title",
  QEX_KYC_DESC = "qex-kyc-desc",
  QEX_ABANDONED_TITLE = "qex-abandoned-title",
  QEX_ABANDONED_DESC = "qex-abandoned-desc",
  QEX_CHOOSE_PAYOUT_METHOD = "qex-choose-payout-method",
  QEX_RECEIVED_YOUR_FUNDS = "qex-received-your-funds",
  QEX_OUTCOME_20151 = "qex-outcome-20151",
  QEX_OUTCOME_20057 = "qex-outcome-20057",
  QEX_OUTCOME_20061 = "qex-outcome-20061",
  QEX_OUTCOME_20059 = "qex-outcome-20059",
  QEX_OUTCOME_20005 = "qex-outcome-20005",
  QEX_OUTCOME_20058 = "qex-outcome-20058",
  QEX_OUTCOME_20051 = "qex-outcome-20051",
  QEX_OUTCOME_40151 = "qex-outcome-40151",
  QEX_OUTCOME_200N7 = "qex-outcome-200N7",
  QEX_SELECT_A_PAYMENT_METHOD = "qex-select-a-payment-method",
  QEX_FEE = "qex-fee",
  QEX_FOOTER_VISA_DESC = "qex-footer-visa-desc",
  QEX_PAYMENT_CONFIRMATION_TITLE = "qex-payment-confirmation-title",
  QEX_REDUCE_VIEW = "qex-reduce-view",
  QEX_EXPAND_VIEW = "qex-expand-view",
  QEX_PAYMENT_CONFIRMATION_TRANSACTION_DETAIL = "qex-payment-confirmation-transaction-detail",
  QEX_TX_DELAY_MSG = "qex-transaction-delay-message",
  QEX_FOOTER_APPLE_DESC = "qex-footer-apple-desc",
  QEX_APPLE_PAYMENT_CONFIRMATION_TITLE = "qex-apple-payment-confirmation-title",
  QEX_APPLE_PAYMENT_CONFIRMATION_DESC_1 = "qex-apple-payment-confirmation-desc-1",
  QEX_APPLE_PAYMENT_CONFIRMATION_BTN = "qex-apple-payment-confirmation-btn",
  QEX_APPLE_PAYMENT_CONFIRMATION_DESC_2 = "qex-apple-payment-confirmation-desc-2",
}
