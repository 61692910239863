import React, { CSSProperties, FunctionComponent, ReactNode } from "react";
import classnames from "classnames";
import { Spinner } from "react-bootstrap";
import { t } from "utils/t";
import { TEXT } from "const";

import "./style.scss";

interface CsButtonProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  theme?: string;
}

/**
 * Set a different style for a button.
 * This should be used exceedingly rarely as it is not customisable by the partner.
 *
 * @param theme
 */
const specialStyle = (theme: string | undefined): CSSProperties | undefined => {
  switch (theme) {
    case "light":
      return {
        background: "rgba(210, 210, 210, 0.9)",
        color: "#444",
      };
  }
};

export const CsButton: React.FC<CsButtonProps> = (props) => {
  return (
    <button
      type="button"
      disabled={props.disabled}
      className={classnames("button", "cs-button", props.className, {
        disabled: props.disabled,
      })}
      onClick={props.onClick}
      style={specialStyle(props.theme)}
    >
      {props.loading ? (
        <Spinner animation="border" role="status" className="spinner">
          <span className="sr-only">{t(TEXT.QEX_LOADING)}...</span>
        </Spinner>
      ) : (
        props.children
      )}
    </button>
  );
};
