import { IconCheck } from "components/icons/check";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
import kycAvatarSuccess from "./kyc-avatar-success.png";

export const KycSuccess = () => {
  const redirectUrl = useSelector(
    (state: AppState) => state.kyc.current?.redirect_url
  );
  // useEffect(() => {
  //   if (redirectUrl) {
  //     setTimeout(() => {
  //       window.open(redirectUrl, "_self");
  //     }, 3000);
  //   }
  // }, [redirectUrl]);
  return (
    <div>
      <div className="logo-wrapper">
        <img src={kycAvatarSuccess} alt="singpass-logo" />
      </div>
      <h2 style={{ textAlign: "center", marginBottom: 60 }}>
        Verify successfully!
      </h2>
      <p>
        {/* We processed to create a Liquid account under your email: johny2412@gmail.com */}
      </p>
      <IconCheck />
    </div>
  );
};
