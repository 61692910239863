import React, { useCallback } from "react";
import "./style.scss";
import { connect, useSelector } from "react-redux";
import { resetForm } from "store/form/actions";
import { DisplayError } from "types";
import { CsButton } from "components/cs-button";
import { AppState } from "store";
import kycAvatarFailed from "./kyc-avatar-failed.png";
import { fetchRedirect } from "utils";
interface BannerViewProps {
  errors: Array<DisplayError>;
  resetForm: () => void;
}

export const BannerViewInner = (props: BannerViewProps) => {
  const redirectUrl = useSelector(
    (state: AppState) => state.kyc.current?.redirect_url
  );
  const sessionId = useSelector(
    (state: AppState) => state.session.current?.session_id
  );
  const redirectStorage =
    sessionId !== undefined ? fetchRedirect(sessionId) : undefined;
  const followRedirect = useCallback(() => {
    if (redirectStorage) {
      window.open(redirectStorage, "_self");
    } else if (redirectUrl) {
      window.open(redirectUrl, "_self");
    }
  }, [redirectUrl, redirectStorage]);

  return (
    <div className="text-center kyc-banned-block">
      <div className="logo-wrapper">
        <img src={kycAvatarFailed} alt="singpass-logo" />
      </div>
      <h2 className="title">Cannot proceed</h2>
      <p>Please contact our customer support for more information.</p>
      <CsButton onClick={followRedirect} >
        Back to home
      </CsButton>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    errors: state.form.errorState,
  };
};

const mapDispatchToProps = (dispatch: (a: any) => void) => ({
  resetForm: () => dispatch(resetForm()),
});

export const BannerView = connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerViewInner);
