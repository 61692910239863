import React, { useEffect } from "react";
import "./style.scss";
import { LiquidOnDark, LiquidOnLight, LinkIcon } from "Icons";
import { t } from "utils/t";
import { TEXT } from "const";

import QexLogo from "./logo512.png";
import { setDynamicHeight } from "utils";

export const UnderMaintenance = () => {
  useEffect(() => setDynamicHeight(410), [setDynamicHeight]);
  return (
    <div className="under-maintenance">
      <div className="container">
        <div className="center-panel">
          <img className="qex-logo" alt="liquid logo" src={QexLogo} />
          <h1>{t(TEXT.QEX_BY_LIQUID)}</h1>
          <h4>{t(TEXT.QEX_UNDER_MAINTERNANCE)}</h4>
          <h4>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.liquid.com/quick-exchange"
            >
              {t(TEXT.QEX_GOTO_QEX_LIQUID_LINK)} <LinkIcon />
            </a>
          </h4>
        </div>
        <div className="footer">
          <LiquidOnDark
            className="liquid-logo liquid-on-dark"
            width={150}
            height={50}
          />
          <LiquidOnLight
            className="liquid-logo liquid-on-light"
            width={150}
            height={50}
          />
        </div>
      </div>
    </div>
  );
};
