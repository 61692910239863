import React from "react";
import { Modal } from "../../../components/modal";
import { TEXT } from "../../../const";
import { t } from "../../../utils";
import { dismissModal } from "../../../store/form/actions";
import { connect } from "react-redux";
import { SigningIcon } from "Icons";

import "./style.scss";

interface SignInSuccessModalProps {
  dismissModal: () => void;
}

export const SignInSuccessModalInner = (props: SignInSuccessModalProps) => {
  setTimeout(props.dismissModal, 2000);
  return (
    <Modal
      icon={<SigningIcon />}
      headingText={t(TEXT.QEX_SIGNING_INTO_LIQUID)}
      onClose={props.dismissModal}
    >
      {t(TEXT.QEX_SIGNING_INTO_LIQUID_DESC)}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: (a: any) => void) => ({
  dismissModal: () => dispatch(dismissModal()),
});

export const SignInSuccessModal = connect(
  null,
  mapDispatchToProps
)(SignInSuccessModalInner);
