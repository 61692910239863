import React, { useState, useEffect } from "react";
import {
  ApiResponseKycParameters,
  KycParameters,
  SumsubStatusEvent,
  SUMSUB_STATUS,
} from "types";
import { KycCollectView } from "./kyc-collect-view";
import { KycIntroViewInner } from "./kyc-intro-view";
import { connect } from "react-redux";
import { Loading } from "components/loading";
import {
  AppState,
  fetchKycApplicant,
  KycState,
  handleSkipPoa,
} from "../../store";
import { api, t } from "utils";
import {
  FORM_STEP,
  SUMSUB_FLOW_NAMES,
  // KYC_DOCUMENT_STATUS,
  COMPLETE_STATUS,
  TEXT,
  KYC_DOCUMENT_STATUS,
  KYC_ELEMENT,
} from "const";
import {
  deriveBackgroundColor,
  deriveCurrentColor,
  deriveErrorColor,
} from "./const";
import { KycStateCategory } from "../../store/kyc/types";
import { KycSingpassView } from "./kyc-singpass-view";
import { KycHandoffView } from "./kyc-handoff-view";
import { CsButton } from "components/cs-button";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconCheck } from "components/icons/check";
import { KycSuccess } from "./kyc-success";

interface KycViewProps {
  kycApplicant: ApiResponseKycParameters | null;
  fetchKycApplicant: () => void;
  applicantCountry: string;
  currentColor: string;
  backgroundColor: string;
  errorColor: string;
  sumsubFlowName: SUMSUB_FLOW_NAMES | null;
  proofOfAddress: KycStateCategory;
  onSkipPoa: () => void;
  stopPollKycState: () => void;
  sessionId: string;
  kycState: KycState;
}

export const KycViewInner = (props: KycViewProps) => {
  const [openAction, setOpenAction] = useState<string | null>(null);

  const {
    sessionId,
    sumsubFlowName,
    proofOfAddress,
    onSkipPoa,
    stopPollKycState,
  } = props;
  useEffect(() => {
    setOpenAction(null);
  }, [sumsubFlowName, setOpenAction]);

  if (sumsubFlowName === null) {
    return <Loading label="Kyc" items={[sumsubFlowName]} />;
  }

  if (
    props.kycState.documents[KYC_ELEMENT.LIVENESS].status ===
      KYC_DOCUMENT_STATUS.APPROVED &&
    props.kycState.documents[KYC_ELEMENT.POI].status ===
      KYC_DOCUMENT_STATUS.APPROVED &&
    props.kycState.documents[KYC_ELEMENT.POA].status ===
      KYC_DOCUMENT_STATUS.APPROVED &&
    props.kycState.documents[KYC_ELEMENT.QUESTIONNAIRE].status ===
      KYC_DOCUMENT_STATUS.APPROVED
  ) {
    return <KycSuccess />;
  }

  if (openAction !== null) {
    return (
      <div>
        <RenderAction
          openAction={openAction}
          sessionId={sessionId}
          kycState={props.kycState}
          onBack={() => setOpenAction(null)}
        />
        <CsButton theme="light" onClick={() => setOpenAction(null)}>
          <FontAwesomeIcon icon={faArrowLeft} />
          &nbsp;
          {t(TEXT.QEX_BACK)}
        </CsButton>
      </div>
    );
  }

  return (
    <KycIntroViewInner
      kycState={props.kycState}
      skippable={
        sumsubFlowName === SUMSUB_FLOW_NAMES.ResidentialAddress &&
        proofOfAddress.deferrable
      }
      onNext={(action: string) => {
        setOpenAction(action);
      }}
      onSkip={() => onSkipPoa()}
      sessionId={props.sessionId}
    />
  );
};
interface RenderActionProps {
  openAction: string;
  sessionId: string;
  kycState: KycState;
  onBack: () => void;
}
const RenderAction = ({
  openAction,
  sessionId,
  kycState,
  onBack,
}: RenderActionProps) => {
  const [applicant, setApplicant] = useState<ApiResponseKycParameters | null>(
    null
  );

  const [sumsubStatus, setSumsubStatus] = useState<SumsubStatusEvent>({
    status: SUMSUB_STATUS.LOADING,
  });

  useEffect(() => {
    if (applicant === null && openAction === "sumsub") {
      api
        .get<KycParameters>(FORM_STEP.KYC_VIEW, "/api/v1/kyc/form")
        .then((result: any) => {
          setApplicant(result.payload);
        });
    }
  }, [applicant, openAction, fetchKycApplicant]);

  switch (openAction) {
    case "sumsub":
      if (applicant !== undefined && applicant !== null) {
        return (
          <div>
            <KycCollectView
              kycApplicant={applicant}
              onSumsubEvent={setSumsubStatus}
              applicantCountry={"JPN"}
            />
            <div style={{ textAlign: "center" }}>
              {sumsubStatus.status === SUMSUB_STATUS.VERIFIED ? (
                <Loading
                  label="Sumsub"
                  items={[sumsubStatus.status === SUMSUB_STATUS.VERIFIED]}
                />
              ) : null}
            </div>
          </div>
        );
      } else {
        return <Loading label="Applicant" items={[applicant]} />;
      }
    case "singpass":
      return (
        <KycSingpassView
          sessionId={sessionId}
          kycState={kycState}
          onBack={onBack}
        />
      );
    case "handoff":
      return <KycHandoffView />;
  }
  return <span>Unknown action: {openAction}</span>;
};

const mapStateToProps = (state: any): Partial<KycViewProps> => {
  const { config, kyc, session } = state as AppState;
  const { kycApplicant } = kyc;
  const sessionDetails = session.current;
  if (sessionDetails === null) {
    throw new Error("Session required");
  }
  if (kyc.current === null) {
    throw new Error("Kyc is required");
  }
  return {
    kycApplicant,
    applicantCountry: sessionDetails.client_info.domicile_country,
    currentColor: deriveCurrentColor(config.custom_styles),
    backgroundColor: deriveBackgroundColor(config.custom_styles),
    errorColor: deriveErrorColor(config.custom_styles),
    proofOfAddress: kyc.current.documents[KYC_ELEMENT.POA],
    sessionId: sessionDetails.session_id,
    kycState: kyc.current,
  };
};

const mapDispatchToProps = (dispatch: (a: any) => void) => {
  return {
    fetchKycApplicant: () => dispatch(fetchKycApplicant()),
    onSkipPoa: () => dispatch(handleSkipPoa(true)),
  };
};

export const KycViewWrapped = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(KycViewInner);
