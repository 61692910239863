import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { t } from "utils/t";
import { TEXT } from "const";

import "./styles.scss";

interface LoadingProps {
  label: string;
  items: Array<any>;
}

export const Loading: React.FC<LoadingProps> = (props) => {
  const [showItems, setShowItems] = useState(false);
  setTimeout(() => setShowItems(true), 10000);
  return (
    <div className="loading-wrapper">
      <Spinner animation="border" role="status" className="spinner">
        <span className="sr-only">{t(TEXT.QEX_LOADING)}...</span>
      </Spinner>
      {showItems ? (
        <div>
          <br />
          <br />
          <span>
            {t(TEXT.QEX_WAITING)}.. {props.label}
          </span>
          <br />.
          {props.items.map((v, i) => (v ? "." : <span key={i}>&nbsp;</span>))}.
        </div>
      ) : null}
    </div>
  );
};
