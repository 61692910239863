import { TEXT } from "../../const";
import { WorldIcon } from "Icons";
import React from "react";
import { connect } from "react-redux";
import { resetForm } from "store/form/actions";
import { DisplayError } from "types";
import { t } from "utils/t";
import "./style.scss";
import { CsButton } from "components/cs-button";

interface ComingSoonProps {
  errorState: Array<DisplayError>;
  resetForm: () => void;
}

export const ComingSoonView = (props: ComingSoonProps) => {
  const firstError = props.errorState[0];
  return (
    <div className="comming-soon">
      <WorldIcon className="icon" />
      <div className="heading">{t(TEXT.QEX_COMING_SOON_HEADING)}</div>
      <div className="infoBg">
        <div className="info">{t(TEXT.QEX_COMING_SOON_INFO)}</div>
      </div>
      <div className="desc">
        <p>{t(TEXT.QEX_COMING_SOON_DESC)}</p>
      </div>
      {firstError !== undefined &&
      firstError.code === "issuer_country_rejected" ? (
        <CsButton
          onClick={() => {
            props.resetForm();
          }}
        >
          {t(TEXT.QEX_COMING_SOON_BUTTON_TEXT)}
        </CsButton>
      ) : null}
      <a
        className="learnmore"
        href="https://liquid.com/quick-exchange"
        rel="noopener noreferrer"
        target="_blank"
      >
        {t(TEXT.QEX_COMING_SOON_LEARN_MORE)}
      </a>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const errorState = state.form.errorState as Array<DisplayError>;
  return {
    errorState,
  };
};

const mapDispatchToProps = (dispatch: (a: any) => void) => {
  return {
    resetForm: () => dispatch(resetForm()),
  };
};

export const ComingSoonWrapped = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ComingSoonView);
