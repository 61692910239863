import { FORM_STEP, KYC_ELEMENT } from "../../const";
import { api } from "../../utils";
import {
  ApiResponse,
  ApiResponseKycParameters,
  Dispatch,
  DisplayError,
  GetState,
  KycParameters,
  MODAL_VIEW,
} from "../../types";
import {
  ReceiveKycState,
  KYC_ACTIONS,
  KycState,
  ReceiveKycParameters,
  SkipPoaPayload,
  RequestNewKycState,
  RequestUpdatedKycState,
} from "./types";
import { clearErrorState, formNext, setShowModal, pushErrorState } from "../form/actions";
import { FORM_TRIGGER_ACTIONS } from "../form/types";
import { KYC_APPLICANT_VALIDATION, KYC_STATE_VALIDATION } from "./validation";

export const requestNewKycState = (): RequestNewKycState => {
  return {
    type: KYC_ACTIONS.REQUEST_NEW_KYC_STATE,
  };
};

export const requestUpdatedKycState = (): RequestUpdatedKycState => {
  return {
    type: KYC_ACTIONS.REQUEST_UPDATED_KYC_STATE,
  };
};

export const receiveKycState = (data: KycState): ReceiveKycState => {
  return {
    type: KYC_ACTIONS.RECEIVE_KYC_STATE,
    payload: data,
  };
};

export const receiveKycParameters = (
  data: ApiResponseKycParameters
): ReceiveKycParameters => {
  return {
    type: KYC_ACTIONS.RECEIVE_KYC_PARAMETERS,
    payload: data,
  };
};

export const skipPoa = (data: boolean): SkipPoaPayload => {
  return {
    type: KYC_ACTIONS.SKIP_POA,
    payload: data,
  };
};

export const handleSkipPoa = (data: boolean) => {
  return (dispatch: (a: any) => Promise<any>, getState: GetState) => {
    dispatch(skipPoa(true));
    dispatch(formNext(FORM_TRIGGER_ACTIONS.HANDLE_SKIP_POA));
  };
};

export const fetchKycState = (context: FORM_STEP | null = null) => {
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<ApiResponse<KycState> | DisplayError> => {
    const { session } = getState();
    if (session.current === null) {
      throw new Error("session must be defined");
    }
    return api
      .get<KycState>(
        context,
        `/api/v1/kyc/state`,
        {},
        KYC_STATE_VALIDATION,
        true
      )
      .then((result) => {
        if (result.success) {
          dispatch(receiveKycState(result.payload));
          dispatch(formNext(FORM_TRIGGER_ACTIONS.FETCH_KYC_STATE));
        } else {
          console.error(FORM_TRIGGER_ACTIONS.FETCH_KYC_STATE_FAILED, result);
          dispatch(pushErrorState(result as DisplayError));
        }
        return result;
      });
  };
};

export const fetchKycApplicant = () => {
  const context = FORM_STEP.KYC_VIEW;
  api.pushLog("API_FETCH_APPLICANT", "Render Kyc", context);
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<ApiResponse<ApiResponseKycParameters> | DisplayError> => {
    const state = getState();
    const { session } = state;
    if (session.current === null) {
      throw new Error("");
    }
    dispatch(clearErrorState({ context }));
    return api
      .get<KycParameters>(
        context,
        "/api/v1/kyc/form",
        {
          session_id: session.current.session_id,
        },
        KYC_APPLICANT_VALIDATION
      )
      .then((result: any) => {
        if (result.success) {
          dispatch(receiveKycParameters(result.payload));
        }
        return result;
      });
  };
};

export const putResponse = (
  context: FORM_STEP | null = null,
  element: KYC_ELEMENT,
  data: {
    // TOS
    version?: string;
  } | {
    // Questionaire
    // version?: string;
    source_of_income?: string;
    annual_income?: string;
    occupation?: string;
  },
) => {
  api.pushLog("API_FETCH_APPLICANT", "Render Kyc", context);
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<ApiResponse<ApiResponseKycParameters> | DisplayError> => {
    const state = getState();
    const { session } = state;
    if (session.current === null) {
      throw new Error("");
    }
    return api
      .put<KycParameters>(
        context,
        `/api/v1/kyc/response/${element}`,
        data,
        KYC_APPLICANT_VALIDATION
      )
      .then((result: any) => {
        if (result.success) {
          dispatch(fetchKycState(context));
        }
        return result;
      });
  };
};
