import { CsButton } from "components/cs-button";
import React, { useState } from "react";
import { FORM_STEP, KYC_ELEMENT } from "const";
import { useDispatch, useSelector } from "react-redux";
import { AppState, putResponse } from "store";
import { questionnaireResponse } from "../../types";
import { Dropdown } from "components/dropdown";
import { QUESTIONNAIRE } from "const";

import "./style.scss";

export const QuestionnaireView = () => {
  const [loading, setLoading] = useState(false);
  const [questionResult, setQuestionResult] =
    useState<questionnaireResponse | null>(null);
  const [occupation, setOccupation] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  const [sourceOfIncome, setSourceOfIncome] = useState("");
  const dispatch = useDispatch();
  const latestVersion = useSelector((state: AppState) => {
    if (
      state.kyc.current == undefined ||
      state.kyc.current.documents == undefined ||
      state.kyc.current.documents[KYC_ELEMENT.PDS] == undefined ||
      state.kyc.current.documents[KYC_ELEMENT.PDS].metadata === undefined
    ) {
      return "";
    }
    return state.kyc.current.documents[KYC_ELEMENT.PDS].metadata.latest_version;
  });
  const singpassCache = useSelector(
    (appState: AppState) => appState.singpass.current
  );

  const handlePostQuestionnaire = async () => {
    // const result = await postQuestionnaire(sessionConf);
    // if (result !== null) {
    //   setQuestionResult(result);
    // }
    dispatch(putResponse(FORM_STEP.TOS_VIEW, KYC_ELEMENT.TOS, latestVersion));
  };

  const onNext = () => {
    // api.post(
    //   FORM_STEP.QUESTIONNAIRE_VIEW,
    //   `/api/v1/singpass/questionnaire`,
    //   {
    //     source_of_income: sourceOfIncome,
    //     annual_income: annualIncome,
    //     occupation: occupation,
    //   },
    //   Joi.any()
    // );
    dispatch(
      putResponse(FORM_STEP.QUESTIONNAIRE_VIEW, KYC_ELEMENT.QUESTIONNAIRE, {
        source_of_income: sourceOfIncome,
        annual_income: annualIncome,
        occupation: occupation,
      })
    );
    setLoading(true);
  };

  return (
    <div className="questionnaire">
      <h2>Questionnaire</h2>
      <div>
        <div className="questionnaire-block">
          <Dropdown
            placeholder="Select..."
            label="Source of fund"
            options={QUESTIONNAIRE.source_of_income}
            onChange={setSourceOfIncome}
          />
          <Dropdown
            placeholder="Select..."
            label="Annual Income"
            options={QUESTIONNAIRE.annual_income}
            onChange={setAnnualIncome}
          />
          <Dropdown
            placeholder="Select..."
            label="Occupation"
            options={QUESTIONNAIRE.occupation}
            onChange={setOccupation}
          />
        </div>
        <footer>
          <div className="footer-content">
            <CsButton
              className="submit-btn"
              onClick={onNext}
              loading={loading}
              disabled={!occupation || !sourceOfIncome || !annualIncome}
            >
              Submit
            </CsButton>
          </div>
        </footer>
      </div>
    </div>
  );
};
