import React, { useCallback, useEffect } from "react";
import { t } from "utils/t";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { CsButton } from "components/cs-button";
import { AppState } from "store";
import kycAvatarPending from "./kyc-avatar-pending.png";
import { fetchRedirect } from "utils";
import { fetchSingpassCache } from "store/singpass";

export const PendingView = () => {
  const dispatch = useDispatch();
  const redirectUrl = useSelector(
    (state: AppState) => state.kyc.current?.redirect_url
  );
  const sessionId = useSelector(
    (state: AppState) => state.session.current?.session_id
  );
  const redirectStorage =
    sessionId !== undefined ? fetchRedirect(sessionId) : undefined;
  const followRedirect = useCallback(() => {
    if (redirectStorage) {
      window.open(redirectStorage, "_self");
    } else if (redirectUrl) {
      window.open(redirectUrl, "_self");
    }
  }, [redirectUrl, redirectStorage]);

  useEffect(() => {
    dispatch(fetchSingpassCache() as any);
    setTimeout(() => {
      dispatch(fetchSingpassCache() as any);
    }, 12000);
  }, []);

  return (
    <div className="text-center kyc-pending-block">
      <div className="logo-wrapper">
        <img src={kycAvatarPending} alt="singpass-logo" />
      </div>
      <h2 className="title">Verification is being processed…</h2>
      <p>This usually takes a few minutes, but can take up to 72 hours.</p>
      <CsButton onClick={followRedirect}>Back to home</CsButton>
    </div>
  );
};
